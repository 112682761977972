<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <PageHeader title="Health And Safety"  backTo="internal"/>
    <main
      class="p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6"
    >
      <!-- Incident Management -->
      <router-link
        to="/internal/healthnsafety/incident"
        class="group bg-white hover:bg-primary-highlight transition ease-in-out duration-100 rounded shadow-md text-secondary p-5 flex items-center relative"
      >
        <svg
          class="h-8 w-8 mr-5 stroke-current"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2.25"
          viewBox="0 0 24 24"
        >
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
        </svg>

        <h2 class="text-lg uppercase">Incidents</h2>
      </router-link>    
    </main>
  </div>
</template>

<script>
const PageHeader = () => import("@/components/PageHeader.vue");

export default {
  name: "HR",
  components: {
    PageHeader,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
